/* Header Link Styles */
.header-link {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 400; /* Regular weight by default */
    line-height: 36px;
    text-align: left;
    border-bottom: 3px solid transparent; /* Default border is transparent */
    padding-bottom: 5px; /* Optional: Add padding for spacing */
    text-decoration: none; /* Remove underline */
    color: black; /* Default text color */
  }
  
  /* Active link styles */
  .header-link.active {
    font-weight: 700; /* Make text bold when active */
    border-bottom: 3px solid #1DBC60; /* Green border when active */
    color: black; /* Ensure text color is black when active */
  }
  
  /* Optional: Add spacing between links */
  .nav-links {
    display: flex;
    gap: 15px; /* Adjust spacing between the links */
  }



  .logo-container {
    overflow: hidden; /* Hide overflow to create a clean scrolling effect */
    position: relative;
  }
  
  .logo-wrapper {
    display: flex;
    animation: scroll 20s linear infinite; /* Adjust duration for speed */
  }
  
  .partner-logo {
    width: 100px; /* Set the width of the logos */
    margin: 0 15px; /* Add some space between logos */
  }
  
  /* Animation for scrolling */
  @keyframes scroll {
    0% {
      transform: translateX(0); /* Start from the left */
    }
    100% {
      transform: translateX(-50%); /* Move left by 50% to create the loop effect */
    }
  }
  