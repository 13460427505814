.partner-title {
    font-size: 2.5rem; /* Adjust font size for the title */
    font-weight: 700; /* Bold text */
    color: #2c3e50; /* Dark color for better readability */
    margin-bottom: 20px; /* Space below the title */
    text-transform: uppercase; /* Make the title uppercase */
  }
  
  .logo-container {
    overflow: hidden; /* Hide overflow to create a clean scrolling effect */
    position: relative;
  }
  
  .logo-wrapper {
    display: flex;
    animation: scroll 20s linear infinite; /* Adjust duration for speed */
  }
  
  .partner-logo {
    width: 100px; /* Set the width of the logos */
    margin: 0 15px; /* Add some space between logos */
  }
  
  /* Animation for scrolling */
  @keyframes scroll {
    0% {
      transform: translateX(0); /* Start from the left */
    }
    100% {
      transform: translateX(-50%); /* Move left by 50% to create the loop effect */
    }
  }
  